<template>
  <div :class="{ backdrop: isLoading }">
    <div
      class="spinner-border text-primary-green"
      role="status"
      v-if="isLoading"
    >
      <span class="sr-only"></span>
    </div>
    <div id="home" class="home d-block d-md-none">
      <div class="video-background">
        <iframe
          class="video-iframe"
          src="https://www.youtube.com/embed/9iAogJaKHzo?autoplay=1&mute=1&loop=1&playlist=9iAogJaKHzo&controls0&modestbranding=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <div class="container content-overlay">
        <div class="row">
          <div class="col-12">
            <h1 class="text-white text-left mt-5 font-weight-bold mb-3 w-75">
              {{ menuItems.tituloMobile }}
            </h1>
            <router-link to="/tour" class="btn btn-secondary text-left">
              {{ menuItems.CTATitulo }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="home-desktop d-none d-md-flex">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/9iAogJaKHzo?autoplay=1&mute=1&loop=1&playlist=9iAogJaKHzo&controls=0&modestbranding=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="col-md-6">
            <h1 class="text-primary-blue text-left mt-5 font-weight-bold mb-3">
              {{ menuItems.TituloDesktop1 }}
              <span class="d-block text-primary-green pt-3 lh35">{{
                menuItems.TituloDesktop2
              }}</span>
            </h1>
            <router-link to="/tour" class="btn btn-secondary text-left mt-4">
              {{ menuItems.CTATitulo }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="more-than-a-tour" class="more-than-a-tour bg-grey-custom py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-8">
            <div class="">
              <img
                src="/img/logo-pedal.png"
                alt="Logo pedal"
                width="90"
                height="80"
                class="my-3"
              />
            </div>
            <p class="pt-3 mb-1 text-left more-than-a-tour-text">
              {{ menuItems.bajada1 }}
              <span class="font-weight-normal more-than-a-tour-text">{{
                menuItems.bajadaDestacada1
              }}</span>
              {{ menuItems.bajada2 }}
              <span class="font-weight-normal more-than-a-tour-text"
                >{{ menuItems.bajadaDestacada2 }},</span
              >
              {{ menuItems.bajada3 }}
            </p>
            <p class="mb-1 text-left more-than-a-tour-text">
              {{ menuItems.bajada4 }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="buenos-aires" class="buenos-aires bg-primary-blue py-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <h2 class="text-white text-left h1 font-weight-bold">
              {{ menuItems.bajadaDestacada3 }}
            </h2>
          </div>
          <div class="col-12 col-md-6">
            <div class="video-container d-none d-md-block">
              <iframe
                src="https://www.youtube.com/embed/Fxcav2htRZw?autoplay=1&mute=1&loop=1&playlist=Fxcav2htRZw&controls=0&modestbranding=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div
              style="
                position: relative;
                padding-bottom: 177.77%;
                height: 0;
                overflow: hidden;
                max-width: 100%;
                background: black;
              "
              class="d-block d-md-none"
            >
              <iframe
                src="https://www.youtube.com/embed/Fxcav2htRZw?autoplay=1&mute=1&loop=1&playlist=Fxcav2htRZw&controls=0&modestbranding=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="unique-experience" class="unique-experience bg-primary-green py-5">
      <div class="container">
        <div class="row justify-content-center py-lg-5">
          <div class="col-12 col-md-8">
            <h3 class="text-white text-left h1 font-weight-bold">
              {{ menuItems.bajadaDestacada4 }}
            </h3>
            <p class="text-white text-left">
              <span class="font-weight-normal">{{ menuItems.bajada6 }}</span>
              {{ menuItems.bajada7 }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-5 col-lg-7">
            <p class="h1 text-primary-blue font-weight-bold pt-5 mb-0">
              {{
                menuItems.tituloDestacado1
                  ? menuItems.tituloDestacado1
                  : menuItems.tituloDestacado
              }}
            </p>
            <p class="h1 text-primary-green font-weight-bold">
              {{ menuItems.tituloDestacado2 }}
            </p>
          </div>
          <div class="col-7 col-lg-5">
            <div
              id="pick-your-experience"
              class="pick-your-experience bg-white"
            >
              <cards-component
                v-if="!isLoading"
                :cardsDataEs="spanishEntrys"
                :cardsDataEn="englishEntrys"
              ></cards-component>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="pick-your-experience"
      class="pick-your-experience bg-white d-md-none"
    >
      <cards-component v-if="!isLoading"></cards-component>
    </div>
  </div>
</template>

<script>
import CardsComponent from "@/components/CardsComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {
    CardsComponent,
  },
  data() {
    return {
      cardOrientation: "row",
      spanishEntrys: null,
      englishEntrys: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    menuItems() {
      return this.currentLanguage === "es"
        ? this.spanishEntrys || {}
        : this.englishEntrys || {};
    },
  },
  methods: {
    async getHomeEsEntrys() {
      try {
        const response = await this.$axios.get(
          "/home-es?populate[card_data][populate]=*&populate[card_data.benefits]=*"
        );
        this.$store.commit(
          "SET_SPANISH_ENTRYS",
          response.data.data[0].attributes.card_data.data[0].attributes
        );
        this.spanishEntrys = response.data.data[0].attributes;
      } catch (error) {
        console.error("Error al consumir la API para español:", error);
      }
    },
    async getHomeEnEntrys() {
      try {
        const response = await this.$axios.get(
          "/home-ens?populate[card_data][populate]=*&populate[card_data.benefits]=*"
        );
        this.$store.commit(
          "SET_ENGLISH_ENTRYS",
          response.data.data[0].attributes.card_data.data[0].attributes
        );
        this.englishEntrys = response.data.data[0].attributes;
      } catch (error) {
        console.error("Error al consumir la API para inglés:", error);
      }
    },
  },
  async created() {
    await Promise.all([this.getHomeEsEntrys(), this.getHomeEnEntrys()]);
    this.isLoading = false;
  },
};
</script>

<style scoped>
.home {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.video-iframe {
  position: absolute;
  top: 50%;
  left: 45%;
  width: 56.25vh;
  height: 100vh;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.content-overlay {
  position: relative;
  z-index: 2;
}
.home-desktop {
  margin-top: 50px;
  height: 350px;
}
@media (min-width: 992px) {
  .home-desktop {
    margin-top: 200px;
    height: 350px;
  }
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.more-than-a-tour-text {
  font-size: 16px;
}

@media (min-width: 992px) {
  .more-than-a-tour-text {
    font-size: 24px;
    line-height: 35px !important;
  }
}
</style>
