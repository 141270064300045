<template>
  <div id="nav">
    <nav class="navbar navbar-light py-0 px-0">
      <div
        class="px-2 w-100 d-flex d-lg-none align-items-center py-4 justify-content-between"
      >
        <router-link to="/">
          <img
            class="nav-logo"
            src="/img/iso-logo-pedal.png"
            alt="Pedal experience logo"
          />
        </router-link>
        <div class="d-flex align-items-center ml-auto">
          <div class="dropdown">
            <button
              class="btn btn-tertiary dropdown-toggle lenguage-button"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                class="nav-logo"
                :src="selectedFlag"
                alt="Selected language"
              />
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button class="dropdown-item" @click="changeLanguage('es')">
                <img class="nav-logo" src="/img/spain.png" alt="Spanish flag" />
              </button>
              <button class="dropdown-item" @click="changeLanguage('en')">
                <img class="nav-logo" src="/img/united.png" alt="USA flag" />
              </button>
            </div>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
      <div
        class="header d-none d-lg-flex justify-content-center align-items-center w-100 px-5"
      >
        <router-link to="/">
          <img
            class="nav-logo"
            src="/img/iso-logo-pedal.png"
            alt="Pedal experience logo"
          />
        </router-link>
        <ul class="d-flex justify-content-center align-items-center w-100">
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            class="nav-item p-2"
          >
            <router-link
              :to="item.route"
              :exact="item.exact"
              :class="item.class"
              active-class="active"
            >
              {{ item.name }}
            </router-link>
          </li>
          <div class="login-buttons d-flex">
            <button
              class="btn btn-primary my-1 mx-2"
              @click="openModal(true)"
              v-if="!isAuth"
            >
              {{ loginText }}
            </button>
            <button
              class="btn btn-secondary my-1 mx-2"
              @click="openModal(false)"
              v-if="!isAuth"
            >
              {{ registerText }}
            </button>
            <button
              class="btn btn-secondary my-1 mx-2"
              @click="logOut()"
              v-if="isAuth"
            >
              {{ logOutText }}
            </button>
          </div>
          <div class="d-flex align-items-center">
            <div class="dropdown">
              <button
                class="btn btn-tertiary dropdown-toggle lenguage-button"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  class="nav-logo"
                  :src="selectedFlag"
                  alt="Selected language"
                />
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button class="dropdown-item" @click="changeLanguage('es')">
                  <img
                    class="nav-logo"
                    src="/img/spain.png"
                    alt="Spanish flag"
                  />
                </button>
                <button class="dropdown-item" @click="changeLanguage('en')">
                  <img class="nav-logo" src="/img/united.png" alt="USA flag" />
                </button>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            class="nav-item p-2"
          >
            <router-link
              :to="item.route"
              :exact="item.exact"
              :class="item.class"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              active-class="active"
            >
              {{ item.name }}
            </router-link>
          </li>
          <div class="login-buttons d-flex flex-column p-5">
            <button
              class="btn btn-primary my-1"
              @click="openModal(true)"
              v-if="!isAuth"
            >
              {{ loginText }}
            </button>
            <button
              class="btn btn-secondary my-1"
              @click="openModal(false)"
              v-if="!isAuth"
            >
              {{ registerText }}
            </button>
            <button
              class="btn btn-secondary my-1 mx-2"
              @click="logOut()"
              v-if="isAuth"
            >
              {{ logOutText }}
            </button>
          </div>
        </ul>
      </div>
    </nav>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- El modal de autenticación -->
          <AuthModal
            v-if="showModal"
            :isLogin="isLogin"
            @toggle-auth-form="toggleAuthForm"
            @close="closeModal"
            @openModal="openModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthModal from "../components/AuthModal.vue";
import { mapGetters, mapActions } from "vuex";
import spanishTexts from "../../navEs.json";
import englishTexts from "../../navEn.json";

export default {
  components: {
    AuthModal,
  },
  name: "NavMenu",
  data() {
    return {
      showModal: false,
      isLogin: false,
    };
  },
  computed: {
    ...mapGetters(["currentLanguage", "isAuthenticated"]),
    isAuth() {
      return this.isAuthenticated ? true : false;
    },
    menuItems() {
      return this.currentLanguage === "es" ? spanishTexts : englishTexts;
    },
    selectedFlag() {
      return this.currentLanguage === "es"
        ? "/img/spain.png"
        : "/img/united.png";
    },
    loginText() {
      const texts = this.currentLanguage === "es" ? "Ingresar" : "Login";
      return texts;
    },
    registerText() {
      const texts = this.currentLanguage === "es" ? "Registrarse" : "Register";
      return texts;
    },
    logOutText() {
      const texts = this.currentLanguage === "es" ? "Cerrar Sesión" : "Log Out";
      return texts;
    },
  },
  methods: {
    ...mapActions(["setLanguage"]),
    logOut() {
      this.$store.commit("SET_USER", null);
      this.$store.commit("SET_AUTH_TOKEN", null);
    },
    changeLanguage(lang) {
      this.setLanguage(lang);
    },
    openModal(value) {
      this.isLogin = value;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    toggleAuthForm(isLogin) {
      this.isLogin = isLogin;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.nav-logo {
  width: 50%;
}

.nav-item {
  border-bottom: 1px solid #dcdee6;
}

@media (min-width: 992px) {
  .nav-logo {
    width: 100%;
  }
  .nav-item {
    border-bottom: none;
  }
}

.navbar-light .navbar-toggler {
  border: none !important;
  padding: 0.25rem 0.75rem;
}

.navbar-light .navbar-toggler-icon {
  width: 30px;
  height: 3px;
  background-color: #231b30;
  display: inline-block;
  position: relative;
  transition: transform 0.3s ease-in-out;
}

.navbar-light .navbar-toggler-icon::before,
.navbar-light .navbar-toggler-icon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-blue);
  transition: transform 0.3s ease-in-out;
}

.navbar-light .navbar-toggler-icon::before {
  top: -10px;
  left: 0;
}

.navbar-light .navbar-toggler-icon::after {
  top: 10px;
  right: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background-color: transparent;
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::before {
  transform: rotate(-179deg) translate(9px, -30px);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon::after {
  transform: rotate(-90deg) translate(-10px, -10px);
}

.active {
  font-weight: 700;
}

ul li:hover {
  background: #4f8a70;
  border-radius: 16px;
  color: #fff;
}

ul li:hover a {
  color: #fff;
}

a:hover {
  text-decoration: none;
  color: var(--color-primary-blue);
}

.lenguage-button img {
  width: 26px;
  height: 18px;
}

.dropdown-item img {
  width: 26px;
  height: 18px;
}

.dropdown-menu {
  left: -35px;
  min-width: 5rem;
}

ul {
  list-style: none;
}

.header {
  height: 120px;
  position: fixed;
  background: #fff;
  top: 0;
  width: 100%;
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Asegúrate de que esté encima de otros elementos */
}

.header ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
}

.header li {
  display: inline;
}
</style>
