import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery/dist/jquery.min.js";
import "@/assets/styles.css";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.prototype.$axios = axios;

// Registrar todas las reglas disponibles
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// Registrar los componentes ValidationProvider y ValidationObserver globalmente
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
