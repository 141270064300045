import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/tour",
    name: "ebike-tour",
    component: () => import("../views/EBikeTour.vue"),
  },
  {
    path: "/tour/circuito-norte",
    name: "north-ride",
    component: () => import("../views/NorthRide.vue"),
  },
  {
    path: "/tour/circuito-sur",
    name: "north-ride",
    component: () => import("../views/SouthRide.vue"),
  },
  {
    path: "/recorridos",
    name: "recorridos",
    component: () => import("../views/RecorridosMaps.vue"),
  },
  {
    path: "/recorridos/norte",
    name: "recorridos",
    component: () => import("../views/RecorridosNorte.vue"),
  },
  {
    path: "/recorridos/sur",
    name: "recorridos",
    component: () => import("../views/RecorridosSur.vue"),
  },
  {
    path: "/contacto",
    name: "contacto",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    component: () => import("../views/FaqsView.vue"),
  },
  {
    path: "/reservas",
    name: "reservas",
    component: () => import("../views/SchedulesView.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default router;
