var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bg-primary-blue d-block d-md-none"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(0),_c('div',{staticClass:"our-experiences d-flex flex-column align-items-center pt-5"},[_c('p',{staticClass:"text-white text-small font-weight-normal mb-0 pb-3"},[_vm._v(" "+_vm._s(_vm.menuItems.ourExperience)+" ")]),_c('router-link',{staticClass:"py-1 text-white btn btn-tertiary",attrs:{"to":"/tour"}},[_vm._v("E-tour e-xperience")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"py-3 mr-2",attrs:{"src":"/img/Ubicacionwhite.svg","alt":"icon phone"}}),_c('p',{staticClass:"text-white text-small font-weight-normal mb-0 py-3"},[_vm._v(" "+_vm._s(_vm.menuItems.location)+" ")])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)],1)])])])]),_c('div',{staticClass:"bg-primary-blue d-none d-md-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"d-flex flex-column align-items-center pt-5"},[_c('p',{staticClass:"our-experiences text-white text-small font-weight-normal mb-0 pb-3"},[_vm._v(" "+_vm._s(_vm.menuItems.ourExperience)+" ")]),_c('router-link',{staticClass:"py-1 text-white btn btn-tertiary",attrs:{"to":"/tour"}},[_vm._v("E-tour e-xperience")])],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"d-flex align-items-end pt-4"},[_c('img',{staticClass:"py-3 mr-2",attrs:{"src":"/img/Ubicacionwhite.svg","alt":"icon phone"}}),_c('p',{staticClass:"text-white text-small font-weight-normal mb-0 py-3"},[_vm._v(" "+_vm._s(_vm.menuItems.location)+" ")])]),_vm._m(5)]),_vm._m(6),_vm._m(7)]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-5 d-flex justify-content-center"},[_c('img',{attrs:{"src":"/img/isologo-pedal-white.png","alt":"Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-white text-center mb-0 pb-3 h6",attrs:{"href":"https://www.google.com/maps/search/?api=1&query=Blvd.+Macacha+Güemes+351,+Hotel+Hilton+Pto+Madero,+CABA","target":"_blank"}},[_vm._v(" Blvd. Macacha Güemes 351 "),_c('span',{staticClass:"d-block py-1"},[_vm._v("Hotel Hilton Pto Madero")]),_c('span',{staticClass:"d-block py-1"},[_vm._v("CABA.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"pb-1 pt-3 mr-2",attrs:{"src":"/img/phonewhite.svg","alt":"icon phone"}}),_c('a',{staticClass:"text-white pb-1 pt-3",attrs:{"href":"tel:+54 9 1168379311"}},[_vm._v("+54 9 1168379311")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"py-1 mr-2",attrs:{"src":"/img/messagewhite.svg","alt":"Icon mail"}}),_c('a',{staticClass:"text-white py-1",attrs:{"href":"mailto:info@pedalexperience.com"}},[_vm._v("info@pex.com.ar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 social-media d-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"social-icon d-flex align-items-center justify-content-center mx-1"},[_c('a',{attrs:{"href":"https://www.instagram.com/pedal.experience?igsh=cDh6NHVpbjN3OGU1"}},[_c('img',{attrs:{"src":"/img/icon-instagram.png","alt":"Icon"}})])])]),_c('p',{staticClass:"text-white py-4"},[_vm._v(" Copyright © 2024, Pedal experience, Inc. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-white text-justify mb-0 pb-3 h6",attrs:{"href":"https://www.google.com/maps/search/?api=1&query=Blvd.+Macacha+Güemes+351,+Hotel+Hilton+Pto+Madero,+CABA","target":"_blank"}},[_vm._v(" Blvd. Macacha Güemes 351 "),_c('span',{staticClass:"d-block py-1"},[_vm._v("Hotel Hilton Pto Madero")]),_c('span',{staticClass:"d-block py-1"},[_vm._v("CABA.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('div',{staticClass:"d-flex flex-column pt-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"pb-1 pt-3 mr-2",attrs:{"src":"/img/phonewhite.svg","alt":"icon phone"}}),_c('a',{staticClass:"text-white pb-1 pt-3",attrs:{"href":"tel:+54 9 1168379311"}},[_vm._v("+54 9 1168379311")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"py-1 mr-2",attrs:{"src":"/img/messagewhite.svg","alt":"Icon mail"}}),_c('a',{staticClass:"text-white py-1",attrs:{"href":"mailto:info@pedalexperience.com"}},[_vm._v("info@pex.com.ar")])]),_c('div',{staticClass:"social-media mt-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('div',{staticClass:"social-icon d-flex align-items-center justify-content-center"},[_c('a',{attrs:{"href":"https://www.instagram.com/pedal.experience?igsh=cDh6NHVpbjN3OGU1"}},[_c('img',{attrs:{"src":"/img/icon-instagram.png","alt":"Icon"}})])]),_c('span',{staticClass:"mb-0 text-white py-1 mx-2"},[_vm._v("Instagram")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('div',{staticClass:"pt-5 d-flex justify-content-center"},[_c('img',{attrs:{"src":"/img/isologo-pedal-white.png","alt":"Logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"text-white text-center py-4"},[_vm._v(" Copyright © 2024, Pedal experience, Inc. ")])])])
}]

export { render, staticRenderFns }