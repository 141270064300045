<template>
  <div id="app">
    <nav-menu></nav-menu>
    <router-view />
    <footer-component></footer-component>
    <wsp-button></wsp-button>
  </div>
</template>

<script>
import NavMenu from "@/views/NavMenu.vue";
import FooterComponent from "./components/FooterComponent.vue";
import WspButton from "./components/WspButton.vue";

export default {
  components: {
    NavMenu,
    FooterComponent,
    WspButton,
  },
};
</script>
