<template>
  <div>
    <div class="bg-primary-blue d-block d-md-none">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="pt-5 d-flex justify-content-center">
              <img src="/img/isologo-pedal-white.png" alt="Logo" />
            </div>
            <div
              class="our-experiences d-flex flex-column align-items-center pt-5"
            >
              <p class="text-white text-small font-weight-normal mb-0 pb-3">
                {{ menuItems.ourExperience }}
              </p>
              <router-link to="/tour" class="py-1 text-white btn btn-tertiary"
                >E-tour e-xperience</router-link
              >

              <div class="d-flex align-items-center">
                <img
                  class="py-3 mr-2"
                  src="/img/Ubicacionwhite.svg"
                  alt="icon phone"
                />

                <p class="text-white text-small font-weight-normal mb-0 py-3">
                  {{ menuItems.location }}
                </p>
              </div>
              <a
                href="https://www.google.com/maps/search/?api=1&query=Blvd.+Macacha+Güemes+351,+Hotel+Hilton+Pto+Madero,+CABA"
                target="_blank"
                class="text-white text-center mb-0 pb-3 h6"
              >
                Blvd. Macacha Güemes 351
                <span class="d-block py-1">Hotel Hilton Pto Madero</span>
                <span class="d-block py-1">CABA.</span>
              </a>
              <div class="d-flex align-items-center">
                <img
                  class="pb-1 pt-3 mr-2"
                  src="/img/phonewhite.svg"
                  alt="icon phone"
                />
                <a href="tel:+54 9 1168379311" class="text-white pb-1 pt-3"
                  >+54 9 1168379311</a
                >
              </div>
              <div class="d-flex align-items-center">
                <img
                  class="py-1 mr-2"
                  src="/img/messagewhite.svg"
                  alt="Icon mail"
                />
                <a
                  href="mailto:info@pedalexperience.com"
                  class="text-white py-1"
                  >info@pex.com.ar</a
                >
              </div>
              <div class="mt-4 social-media d-flex flex-column">
                <div class="d-flex align-items-center justify-content-center">
                  <div
                    class="social-icon d-flex align-items-center justify-content-center mx-1"
                  >
                    <a
                      href="https://www.instagram.com/pedal.experience?igsh=cDh6NHVpbjN3OGU1"
                    >
                      <img src="/img/icon-instagram.png" alt="Icon"
                    /></a>
                  </div>
                </div>
                <p class="text-white py-4">
                  Copyright © 2024, Pedal experience, Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-blue d-none d-md-block">
      <div class="container">
        <div class="row">
          <div class="col-3">
            <div class="d-flex flex-column align-items-center pt-5">
              <p
                class="our-experiences text-white text-small font-weight-normal mb-0 pb-3"
              >
                {{ menuItems.ourExperience }}
              </p>
              <router-link to="/tour" class="py-1 text-white btn btn-tertiary"
                >E-tour e-xperience</router-link
              >
            </div>
          </div>
          <div class="col-3">
            <div class="d-flex align-items-end pt-4">
              <img
                class="py-3 mr-2"
                src="/img/Ubicacionwhite.svg"
                alt="icon phone"
              />

              <p class="text-white text-small font-weight-normal mb-0 py-3">
                {{ menuItems.location }}
              </p>
            </div>
            <a
              href="https://www.google.com/maps/search/?api=1&query=Blvd.+Macacha+Güemes+351,+Hotel+Hilton+Pto+Madero,+CABA"
              target="_blank"
              class="text-white text-justify mb-0 pb-3 h6"
            >
              Blvd. Macacha Güemes 351
              <span class="d-block py-1">Hotel Hilton Pto Madero</span>
              <span class="d-block py-1">CABA.</span>
            </a>
          </div>
          <div class="col-3">
            <div class="d-flex flex-column pt-4">
              <div class="d-flex align-items-center">
                <img
                  class="pb-1 pt-3 mr-2"
                  src="/img/phonewhite.svg"
                  alt="icon phone"
                />
                <a href="tel:+54 9 1168379311" class="text-white pb-1 pt-3"
                  >+54 9 1168379311</a
                >
              </div>
              <div class="d-flex align-items-center">
                <img
                  class="py-1 mr-2"
                  src="/img/messagewhite.svg"
                  alt="Icon mail"
                />
                <a
                  href="mailto:info@pedalexperience.com"
                  class="text-white py-1"
                  >info@pex.com.ar</a
                >
              </div>
              <div class="social-media mt-1">
                <div class="d-flex align-items-center justify-content-start">
                  <div
                    class="social-icon d-flex align-items-center justify-content-center"
                  >
                    <a
                      href="https://www.instagram.com/pedal.experience?igsh=cDh6NHVpbjN3OGU1"
                    >
                      <img src="/img/icon-instagram.png" alt="Icon"
                    /></a>
                  </div>
                  <span class="mb-0 text-white py-1 mx-2">Instagram</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="pt-5 d-flex justify-content-center">
              <img src="/img/isologo-pedal-white.png" alt="Logo" />
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <p class="text-white text-center py-4">
              Copyright © 2024, Pedal experience, Inc.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import spanishTexts from "../../footerEs.json";
import englishTexts from "../../footerEn.json";

export default {
  name: "NavMenu",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    menuItems() {
      return this.currentLanguage === "es" ? spanishTexts : englishTexts;
    },
  },
  methods: {
    ...mapActions(["setLanguage"]),
    changeLanguage(lang) {
      this.setLanguage(lang);
    },
  },
};
</script>

<style>
.social-icon {
  background: #dcdee6;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.social-icon-dark {
  background: transparent;
  border: 1px solid #dcdee6;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
</style>
