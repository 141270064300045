<template>
  <div>
    <a
      href="https://wa.me/541168379311?text=Hello! I would like to get more information."
      class="whatsapp_float"
      target="_blank"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
      />
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style>
.whatsapp_float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
}

.whatsapp_float img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
