<template>
  <div>
    <div
      class="pt-3 pb-5 d-flex justify-content-lg-around"
      :class="{
        'flex-column align-items-center align-items-lg-start':
          cardOrientation === 'column',
        'overflow-auto': cardOrientation !== 'column',
      }"
      v-if="Array.isArray(cardsData)"
    >
      <div
        class="bg-primary-green product-card m-2 d-flex flex-column"
        v-for="(card, index) in cardsData"
        :key="index"
      >
        <div
          class="d-flex flex-column card-logo-container"
          v-if="card && card.attributes && card.attributes.img"
          :style="{ backgroundImage: `url(${card.attributes.img})` }"
        >
          <div class="logo-card mb-2 mr-2 bg-primary-green">
            <img class="p-2" :src="card.attributes.logo" alt="Title" />
          </div>
        </div>
        <div class="card-description h-100 d-flex flex-column bg-white rounded">
          <div class="p-2">
            <p class="font-weight-normal h5 mt-2 mb-0">
              {{ card.attributes.name }}
            </p>
            <p class="font-weight-normal mb-0" v-if="card.attributes.ride">
              {{ card.attributes.ride }}
            </p>
            <p class="mb-0 text-primary-blue">
              {{ card.attributes.bajada1 }}
            </p>
          </div>
          <div
            class="product-description mx-1 p-2 d-flex flex-column align-items-center"
            v-if="card.attributes.price != ''"
          >
            <div class="d-flex align-items-center w-100">
              <div class="d-flex flex-column testi">
                <p class="mb-0 h5 font-weight-normal text-primary-blue">
                  {{ card.attributes.price }}
                </p>
              </div>
              <div class="d-flex flex-column teston">
                <p class="h5 font-weight-normal mb-1 text-primary-blue">
                  {{ whatsIncluded }}
                </p>
                <div class="d-flex align-items-center my-1">
                  <img
                    :src="
                      card.attributes.benefits.data[0].attributes
                        .firstBenefitIcon
                    "
                    class="product-description-icons mr-2"
                    alt="icon"
                  />
                  <p class="text-small font-weight-regular mb-0">
                    {{
                      card.attributes.benefits.data[0].attributes.firstBenefit
                    }}
                  </p>
                </div>
                <div class="d-flex align-items-center my-1">
                  <img
                    :src="
                      card.attributes.benefits.data[0].attributes
                        .secondBenefitIcon
                    "
                    class="product-description-icons mr-2"
                    alt="icon"
                  />
                  <p class="text-small font-weight-regular mb-0">
                    {{
                      card.attributes.benefits.data[0].attributes.secondBenefit
                    }}
                  </p>
                </div>
                <div class="d-flex align-items-center my-1">
                  <img
                    :src="
                      card.attributes.benefits.data[0].attributes
                        .thirdBenefitIcon
                    "
                    class="product-description-icons mr-2"
                    alt="icon"
                  />
                  <p class="text-small font-weight-regular mb-0">
                    {{
                      card.attributes.benefits.data[0].attributes.thirdBenefit
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="hour-and-tour d-flex justify-content-end teston">
              <div class="d-flex align-items-center my-1 mr-2">
                <img
                  :src="
                    card.attributes.benefits.data[0].attributes.distanceIcon
                  "
                  class="product-description-icons mr-2"
                  alt="icon"
                />
                <p class="font-weight-normal mb-0">
                  {{ card.attributes.benefits.data[0].attributes.distance }}
                </p>
              </div>
              <div class="d-flex align-items-center my-1">
                <img
                  :src="card.attributes.benefits.data[0].attributes.timeIcon"
                  class="product-description-icons mr-2"
                  alt="icon"
                />
                <p class="font-weight-normal mb-0">
                  {{ card.attributes.benefits.data[0].attributes.time }}
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center my-2">
            <router-link
              :to="card.attributes.ctaLink"
              class="btn-secondary"
              v-if="card.attributes.ctaLink"
              >{{ card.attributes.cta }}</router-link
            >
            <button
              class="btn-secondary"
              v-else
              @click="selectedProduct(product)"
            >
              {{ card.attributes.cta }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="pt-3 pb-5 d-flex"
      :class="{
        'flex-column align-items-center align-items-lg-start':
          cardOrientation === 'column',
        'overflow-auto': cardOrientation !== 'column',
      }"
      v-else
    >
      <div class="bg-primary-green product-card m-2 d-flex flex-column">
        <div
          class="d-flex flex-column card-logo-container"
          v-if="cardsData && cardsData.img"
          :style="{ backgroundImage: `url(${cardsData.img})` }"
        >
          <div class="logo-card mb-2 mr-2 bg-primary-green">
            <img class="p-2" :src="cardsData.logo" alt="Title" />
          </div>
        </div>
        <div
          class="card-description d-flex flex-column bg-white rounded"
          v-if="cardsData"
        >
          <div class="p-2">
            <p class="font-weight-normal h5 mt-2 mb-0">
              {{ cardsData.name }}
            </p>
            <p class="font-weight-normal mb-0" v-if="cardsData.ride">
              {{ cardsData.ride }}
            </p>
            <p class="mb-0 text-primary-blue">
              {{ cardsData.bajada1 }}
            </p>
          </div>
          <div
            class="product-description mx-1 p-2 d-flex flex-column align-items-center"
            v-if="cardsData.price != ''"
          >
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column testi">
                <p class="mb-0 h5 font-weight-normal text-primary-blue">
                  {{ cardsData.price }}
                </p>
              </div>
              <div class="d-flex flex-column teston">
                <p class="h5 font-weight-normal mb-1 text-primary-blue">
                  {{ whatsIncluded }}
                </p>
                <div class="d-flex align-items-center my-1">
                  <img
                    :src="
                      cardsData.benefits.data[0].attributes.firstBenefitIcon
                    "
                    class="product-description-icons mr-2"
                    alt="icon"
                  />
                  <p class="text-small font-weight-regular mb-0">
                    {{ cardsData.benefits.data[0].attributes.firstBenefit }}
                  </p>
                </div>
                <div class="d-flex align-items-center my-1">
                  <img
                    :src="
                      cardsData.benefits.data[0].attributes.secondBenefitIcon
                    "
                    class="product-description-icons mr-2"
                    alt="icon"
                  />
                  <p class="text-small font-weight-regular mb-0">
                    {{ cardsData.benefits.data[0].attributes.secondBenefit }}
                  </p>
                </div>
                <div class="d-flex align-items-center my-1">
                  <img
                    :src="
                      cardsData.benefits.data[0].attributes.thirdBenefitIcon
                    "
                    class="product-description-icons mr-2"
                    alt="icon"
                  />
                  <p class="text-small font-weight-regular mb-0">
                    {{ cardsData.benefits.data[0].attributes.thirdBenefit }}
                  </p>
                </div>
              </div>
            </div>
            <div class="hour-and-tour d-flex justify-content-end teston">
              <div class="d-flex align-items-center my-1 mr-2">
                <img
                  :src="cardsData.benefits.data[0].attributes.distanceIcon"
                  class="product-description-icons mr-2"
                  alt="icon"
                />
                <p class="font-weight-normal mb-0">
                  {{ cardsData.benefits.data[0].attributes.distance }}
                </p>
              </div>
              <div class="d-flex align-items-center my-1">
                <img
                  :src="cardsData.benefits.data[0].attributes.timeIcon"
                  class="product-description-icons mr-2"
                  alt="icon"
                />
                <p class="font-weight-normal mb-0">
                  {{ cardsData.benefits.data[0].attributes.time }}
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center my-2">
            <router-link
              :to="cardsData.ctaLink"
              class="btn-secondary"
              v-if="cardsData.ctaLink"
              >{{ cardsData.cta }}</router-link
            >
            <button
              class="btn-secondary"
              v-else
              @click="selectedProduct(cardsData)"
            >
              {{ cardsData.cta }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CardsComponent",
  data() {
    return {
      cardsData: null,
    };
  },
  props: {
    cardOrientation: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["currentLanguage", "spanishEntrys", "englishEntrys"]),
    whatsIncluded() {
      return this.currentLanguage === "es"
        ? "¿Qué Incluye?"
        : "What's included?";
    },
  },
  methods: {
    selectedProduct(product) {
      this.$emit("selected-product", product);
    },
    updateCardsData() {
      this.cardsData =
        this.currentLanguage === "es" ? this.spanishEntrys : this.englishEntrys;
    },
  },
  mounted() {
    this.updateCardsData();

    const url = window.location.pathname; // Obtén el path de la URL actual
    const lastPathSegment = url.split("/").filter(Boolean).pop();
    console.log(lastPathSegment);
  },
  watch: {
    currentLanguage() {
      this.updateCardsData();
    },
  },
};
</script>

<style>
.card-logo-container {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px 10px 0px 0px;
}
.logo-card {
  display: flex;
  align-self: end;
  margin-top: auto;
  width: 100px;
  border-radius: 8px;
}
.product-card {
  border: 2px solid var(--color-primary-blue);
  min-width: 335px;
  border-radius: 12px;
}

.product-description {
  background: #dcdee6;
  border-radius: 16px;
}
.date-card-container:hover {
  cursor: pointer;
}
.card-title {
  border-radius: 12px 12px 0 0;
}
.card-description {
  border-radius: 0 0 12px 12px;
}
.tour-description {
  font-size: 16px;
  color: var(--color-primary-blue);
}
.card-img img {
  max-width: 100%;
}
.h-800 {
  height: 685px;
}
.card-logo-container {
  min-height: 200px;
}
.product-description-icons {
  width: 14px;
  height: 14px;
}
.testi {
  width: 35%;
}
.teston {
  width: 65%;
}
</style>
